import React from 'react';
import PropTypes from 'prop-types';
import partial from 'partial';

import FilterMenuFilterList from  "./FilterMenuFilterList";
import "./FilterMenu.scss"

export default class FilterMenu extends React.Component {
    constructor(props) {
        super(props);

        this.lists = {
            'housewives': [], 
            'franchises': [], 
            'seasons': []
        };

        // Get data for each list
        this.props.taglines.forEach(({ node }) => {
            if(node.housewife.fullName) {
                this.lists.housewives.push(node.housewife.fullName);
            }
            if(node.season.number) {
                this.lists.seasons.push(node.season.number);
            }
            if(node.season.franchise && node.season.franchise.city) {
                this.lists.franchises.push(node.season.franchise.city);
            }
        });

        let state = {};

        // Sort each list
        Object.keys(this.lists).forEach((k) => { 
            this.lists[k].sort(); 

            state[k] = {};

            this.lists[k].forEach((el) => { state[k][el] = true });
        });

        // Set initial state
        this.state = state;

        this.handleListChange = this.handleListChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    handleListChange(listName, list) {
        this.setState({ [listName]: list });

        let taglines = this.props.taglines;

        this.props.onChangeTaglines(taglines.map(({ node }) => {
            switch(listName) {
                case 'housewives':
                    node.showTagline = this.state.housewives[node.housewife.fullName]
                    break;
                case 'franchises':
                    node.showTagline = this.state.franchises[node.season.franchise.city]
                    break;
                case 'seasons':
                    node.showTagline = this.state.seasons[node.season.number]
                    break;
                default: 
                    break;
            }

            return { node: node }
        }));
    }

    reset() {
        Object.keys(this.lists).forEach((l) => {
            let newList = {};

            Object.keys(this.state[l])
                .forEach((j) => {
                    newList[j] = true;
                });

            this.handleListChange(l, newList);
        });
    }

    render() {
        return (
            <div className="filter-menu">
                <FilterMenuFilterList 
                    list={ this.state.housewives } 
                    title="Housewives"
                    onListChange={ partial(this.handleListChange, 'housewives') } />
                <FilterMenuFilterList 
                    list={ this.state.franchises } 
                    title="Franchises" 
                    onListChange={ partial(this.handleListChange, 'franchises') }/>
                <FilterMenuFilterList 
                    list={ this.state.seasons } 
                    title="Seasons"
                    onListChange={ partial(this.handleListChange, 'seasons') } />
                <div className="filter-menu-buttons">
                    <button className="filter-menu-button" onClick={ this.reset }>Reset</button>
                    <button className="filter-menu-button" onClick={ this.props.hide }>Close</button>
                </div>
            </div>
        );
    }
}

FilterMenu.propTypes = {
    taglines: PropTypes.arrayOf(PropTypes.object)
}
import React from "react";
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'

import FilterMenu from  "../filtermenu/FilterMenu";
import FilterMenuSearch from '../filtermenu/FilterMenuSearch';

import "./UtilityMenu.scss";

export default class UtilityMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPane: false
        };

        this.onBehindClick = this.onBehindClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onItemKeydown = this.onItemKeydown.bind(this);
        this.onFilterList = this.onFilterList.bind(this);

        this.filterIcon = <FontAwesomeIcon icon={faFilter} />;
        this.reverseIcon = <FontAwesomeIcon icon={faExchangeAlt} style={ {transform: 'rotate(90deg)'} } />;
        this.searchIcon = <FontAwesomeIcon icon={faSearch} />;

        this.menuRef = React.createRef();
        this.searchRef = React.createRef();
    }

    componentDidUpdate() {
        if(this.shouldStick()) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }

        if(this.state.showPane !== false) {
            this.menuRef.current.scrollIntoView()

            if(this.state.showPane === 'search') {
                this.searchRef.current.focus();
            }
        } 
    }

    shouldStick() {
        return this.state.showPane !== false && this.state.showPane === "filter"
    }

    toggle(pane) {
        pane = pane || false;

        this.setState({ showPane: pane })
    }

    onBehindClick(e) {
        this.toggle()
    }

    onItemClick(e) {
        if(e.target.dataset['toggle']) {
            e.preventDefault();
            if(this.state.showPane !== e.target.dataset['toggle']) {
                this.setState({ showPane: e.target.dataset['toggle'] })
            } else {
                this.setState({ showPane: false })
            }
        } else if(e.target.dataset['action'] === 'reverse') {
            this.props.onChangeTaglines(this.props.taglines.slice().reverse());
        }
    }

    onItemKeydown(e) {
        if(e.keyCode === 13) {
            this.onItemClick(e);
        }
    }

    onFilterList(list) {
        this.props.onChangeTaglines(list);
    }

    render() {
        return (
            <>
                <div role="button" tabIndex="-1" className="utility-menu-behind" 
                    onClick={ this.onBehindClick } 
                    style={ this.shouldStick() ? { display: 'block' } : {} }></div>
                <div ref={ this.menuRef } className={ 'utility-menu-holder ' + (this.state.showPane !== false ? 'utility-menu--open' : '') }>
                    <div className='utility-menu'>
                        <div role="button" tabIndex={0} className="utility-menu-item" data-toggle="filter" onKeyDown={ this.onItemKeydown } onClick={ this.onItemClick }>
                            { this.filterIcon } Filter
                        </div>
                        <div role="button" tabIndex={0} className="utility-menu-item" data-toggle="search" onKeyDown={ this.onItemKeydown } onClick={ this.onItemClick }>
                            { this.searchIcon } Search
                        </div>
                        <div role="button" tabIndex={0} className="utility-menu-item" data-action="reverse" onKeyDown={ this.onItemKeydown } onClick={ this.onItemClick }>
                            { this.reverseIcon } Reverse
                        </div>
                    </div>
                    <div className="utility-menu-pane" data-toggle-pane="filter"
                        style={ this.state.showPane === 'filter' ? { display: 'flex' } : { display: 'none' } }>
                        <FilterMenu taglines={ this.props.taglines } 
                            onChangeTaglines={ this.props.onChangeTaglines }
                            hide={() => this.toggle() }/>
                    </div>
                    <div className="utility-menu-pane" data-toggle-pane="search"
                        style={ this.state.showPane === 'search' ? { display: 'flex' } : { display: 'none' } }>
                        <FilterMenuSearch list={ this.props.taglines } 
                            placeholder={ 'Search Taglines' }
                            onFilterList={ this.onFilterList }
                            inputRef={ this.searchRef }/>
                    </div>
                </div>
            </>
        );
    }
}

UtilityMenu.propTypes = {
    taglines: PropTypes.arrayOf(PropTypes.object)
}
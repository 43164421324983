import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from "debounce";

import filter from 'simple-text-search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


export default class FilterMenuSearch extends React.Component {
    constructor(props) {
        super(props);

        this.get = filter(props.list);

        this.clear = this.clear.bind(this);
        this.onFilterList = debounce(this.onFilterList, 150);

        this.closeIcon = <FontAwesomeIcon icon={faTimes} onClick={ this.clear } />;
        this.searchRef = this.props.inputRef || React.createRef();

        this.state = { value: '' };
    }

    clear() {
        // clear value using setter
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set.call(this.searchRef.current, '');

        // trigger event 
        let changeEvent = new Event('change', { bubbles: true });
        this.searchRef.current.dispatchEvent(changeEvent);
    }

    onChangeInput(e) {
        this.setState({ value: e.target.value });
        this.onFilterList(this.get(e.target.value));
    }

    onFilterList(newList) {
        this.props.onFilterList(newList);
    }

    render() {
        return (
            <div className="filter-menu-search-holder">
                <input type="text" name="search" className="filter-menu-search"
                    onChange={ (e) => this.onChangeInput(e) }
                    placeholder={ this.props.placeholder || '' }
                    ref={ this.searchRef } />
                { this.state.value !== '' && this.closeIcon }
            </div>
        );
    }
}

FilterMenuSearch.propTypes = {
    list: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.object, PropTypes.string)),
    onFilterList: PropTypes.func,
    placeholder: PropTypes.string
}
import React from "react";

import PropTypes from 'prop-types'

export default function TaglineSchema({ housewife, franchise: { city },  tagline }) {
    const scriptContent = `
        {
        "@context": "http://schema.org/",
        "@type": "Quotation",
        "spokenByCharacter": {
            "@type": "Person",
            "name": "${housewife.fullName}"
        },
        "text": "${tagline}",
        "isPartOf": {
            "@type": "TVSeries",
            "name": "The Real Housewives of ${city}"
        }
        }
    `;

    return (
        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: scriptContent }}></script>
    );
}

TaglineSchema.propTypes = {
    housewife: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
    }),
    franchise: PropTypes.shape({
        city: PropTypes.string.isRequired,
    }),
    tagline: PropTypes.string.isRequired,
}
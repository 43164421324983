export default function(taglines) {
    // kind of hacky but for now...
    let taglineDedupe = {};

    taglines.edges = taglines.edges
        .filter(
            ({ node }) => { 
                if(!taglineDedupe[node.rank]) { 
                    taglineDedupe[node.rank] = true; 
                    return true; 
                } 

                return false;
            })
        .map(
            (edge) => {
                // flatten seasons
                let node = edge.node;
                node.season = (node.seasonList.edges && node.seasonList.edges.length) ? node.seasonList.edges[0].node : node.season;

                // hw images
                node.housewife.image = null;
                if(node.housewife.housewifeimageSet.length) {
                    let crops = node.housewife.housewifeimageSet[0].crops;

                    for(let i=0; i<crops.length; i++) {
                        if(crops[i].name === 'square_medium') {
                            node.housewife.image = crops[i].url;
                        }
                    }
                }

                return edge;
            });
    
    return taglines.edges;
}
import React from "react";

import Ad from '../ads/Ad';
import Tagline from '../tagline/Tagline';
import UtilityMenu from '../utilitymenu/UtilityMenu';

export default class TaglineList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            taglines: props.taglines
        }

        this.onChangeTaglines = this.onChangeTaglines.bind(this);
    }

    onChangeTaglines(taglines) {
        this.setState({ taglines: taglines })
    }

    render() {
        let taglines = this.state.taglines;

        let filter_menu = this.props.showFilterMenu ? <UtilityMenu taglines={ taglines } onChangeTaglines={ this.onChangeTaglines } /> : '';

        let maxAds = Math.min(taglines.length / 10, 10);
        let adInterval = taglines.length / maxAds;

        return (
            <>
            { filter_menu }
            { taglines.map(({ node }, index) => {
                let ad = "";
                if (index % adInterval === 0 && index !== taglines.length) {
                    ad = <Ad></Ad>;
                }

                return <React.Fragment key={ node.rank }><Tagline {...node} showImage={ this.props.showImages }/>{ad}</React.Fragment>;
            }) }
            { <Ad></Ad> }
            </>
        );
    }
}

TaglineList.defaultProps = {
    showImages: true
}
import React from 'react';

import FilterMenuSearch from './FilterMenuSearch';

export default class FilterMenuFilterList extends React.Component {
    constructor(props) {
        super(props);

        this.onFilterList = this.onFilterList.bind(this);
        this.onItemToggle = this.onItemToggle.bind(this);
        this.onOnlyClick = this.onOnlyClick.bind(this);

        this.state = { list: Object.keys(props.list) };
    }

    onItemToggle(e) {
        let list = this.props.list;

        list[e.target.name] = e.target.checked;

        this.props.onListChange(list);
    }

    onOnlyClick(e) {
        let list = this.props.list;

        Object.keys(list).forEach((el) => { list[el] = (el === e.target.value ? true : false) });

        this.props.onListChange(list);
    }

    onFilterList(newList) {
        this.setState({ list: newList });
    }

    render() {
        let listKeys = Object.keys(this.props.list);
        let search = listKeys.length > 15 
            ? <FilterMenuSearch list={ listKeys } 
                onFilterList={ this.onFilterList } 
                placeholder={ 'Search ' + this.props.title } /> 
            : '';

        return (
            <>
                <h4 className="filter-list-title">{ this.props.title }</h4>
                { search }
                <ul className="filter-list">
                    { 
                        this.state.list
                            .map((item) => {
                                let selected = this.props.list[item];

                                return (
                                    <li key={ item } className="filter-list-item">
                                        <label>
                                            <input name={ item } type="checkbox" 
                                                checked={ selected === true } 
                                                onChange={this.onItemToggle}/> { item }
                                        </label>
                                        <button className="filter-list-only" value={ item } onClick={this.onOnlyClick}>only</button>
                                    </li>
                                )
                            }) 
                    }
                </ul>
            </>
        );
    }
}
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import TaglineSchema from "../schemaorg/TaglineSchema.js";
import "./Tagline.scss";

export default class Tagline extends React.PureComponent {
    constructor(props) {
        super(props);

        this.className = this.createClassName(props);
        this.meta = this.createMeta(props);
        this.schema = this.createSchema(props);
        this.wifeLink = this.createWifeLink(props);
    }

    createClassName(props) {
        let franchise = props.season.franchise,
            housewife = props.housewife;

        let className = `tagline-container tagline-container--${franchise.slug} hw-color-bg-light--${franchise.slug}`;

        if(housewife.image == null || !this.props.showImage) {
            className += ` tagline-container--no-image`;
        }

        return className;
    }

    createMeta(props) {
        let meta = '',
            franchise = props.season.franchise,
            season = props.season;

        if(props.showMeta) {
            let seasonLink = <Link to={ '/' + ['season', franchise.slug, season.number].join('/') }>Season { season.number }</Link>,
                franchiseText = '';

            if(franchise.acronym && franchise.acronym !== '') {
                franchiseText = `#${franchise.acronym}`;
            } else if(franchise.city && franchise.city !== '') {
                franchiseText = `${franchise.city}`;
            }

            if(franchiseText.length) {
                meta = <>{ seasonLink }, <Link to={ '/' + ['franchise', franchise.slug].join('/') }>{ franchiseText }</Link></>
            } else {
                meta = seasonLink;
            }
        }

        return meta;
    }

    createSchema(props) {
        let housewife = props.housewife,
            franchise = props.season.franchise,
            tagline = props.tagline;

        return <TaglineSchema housewife={ housewife } tagline={ tagline } franchise={ franchise } />;
    }

    createWifeLink(props) {
        let housewife = props.housewife;

        return <Link className="tagline-housewife" to={ '/' + ['wife', housewife.slug].join('/') }>{ housewife.fullName }</Link>;
    }

    render() {
        return (
            <>
                { this.schema }
                <div id={ 'rank_' + this.props.rank } className={this.className} style={ this.props.showTagline === true ? {} : { display: 'none' } }>
                    <div className="tagline-content">
                        <div className={ 'tagline-rank hw-color-bg--' + this.props.season.franchise.slug }>{ this.props.rank }</div>
                        <div className="tagline-info">
                            <div className="tagline-text">
                                { this.props.tagline }
                            </div>
                            <div className="tagline-housewife-holder"> - { this.wifeLink }</div>
                        </div>
                        { this.props.showImage && this.props.housewife.image != null && (
                            <div className="tagline-housewife-image-holder">
                                <img src={ this.props.housewife.image } className="tagline-housewife-image" alt={ `${this.props.tagline} - ${this.props.housewife.fullName}` }/>
                            </div>
                        )}
                        { this.meta !== '' && (
                                <div className="tagline-meta">{ this.meta }</div>
                                )}
                        { this.props.blurb && (<div className="tagline-blurb">{ this.props.blurb }</div>) }
                    </div>
                </div>
            </>
        );
    }
}

Tagline.defaultProps = {
    showImage: true,
    showMeta: true,
    showTagline: true
}

Tagline.propTypes = {
    housewife: PropTypes.shape({
        image: PropTypes.string,
        fullName: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }),
    rank: PropTypes.number.isRequired,
    season: PropTypes.shape({
        number: PropTypes.number,
        franchise: PropTypes.shape({
            city: PropTypes.string.isRequired,
            acronym: PropTypes.string,
            slug: PropTypes.string
        })
    }),
    tagline: PropTypes.string.isRequired,
}